import { mergeProps, splitProps, useContext } from 'solid-js';
import { Dynamic } from 'solid-js/web';
import { twMerge } from '@troon/tailwind-preset/merge';
import { Button } from '@kobalte/core/button';
import { useFocusable } from '../providers/focusable';
import { FormContext } from './form';
import type { ComponentProps, JSX } from 'solid-js';
import type { Link } from './link';

type AllProps = {
	action?: 'danger';
	appearance?: 'primary' | 'secondary' | 'secondary-current' | 'transparent' | 'transparent-current';
	disabled?: boolean;
};
type LinkProps<T extends typeof Link = typeof Link> = {
	as: T;
} & AllProps &
	ComponentProps<T>;

type ButtonProps = {
	as?: 'button' | undefined;
} & AllProps &
	JSX.ButtonHTMLAttributes<HTMLButtonElement>;

type Props = LinkProps | ButtonProps;
export function PillButton(inputProps: Props) {
	const { data } = useContext(FormContext);
	const focusableProps = useFocusable<Props>();
	const allProps = mergeProps({ as: Button, appearance: 'primary' as const }, focusableProps, inputProps);
	const [props, rest] = splitProps(allProps, ['as', 'children', 'action', 'appearance', 'class']);

	return (
		<Dynamic
			{...rest}
			component={props.as}
			aria-disabled={props.as !== 'button' ? data?.pending || rest.disabled : rest.disabled}
			formnovalidate
			class={twMerge(
				'flex flex-row flex-nowrap items-center gap-2 text-nowrap rounded-full border border-neutral px-4 py-1.5 text-sm text-neutral-800 outline-none transition-all hover:border-brand hover:bg-brand-100 focus-visible:ring focus-visible:ring-brand-100 active:scale-95 aria-pressed:border-brand-700 aria-pressed:bg-brand-700 aria-pressed:text-white',
				props.class,
			)}
		>
			{props.children}
		</Dynamic>
	);
}
