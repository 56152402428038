import { createContext, createUniqueId, mergeProps, useContext, createEffect, splitProps } from 'solid-js';
import { useFocusable } from '../providers/focusable';
import { FormContext, disableAutocomplete } from './form';
import { InputContext, FieldErrors, Field, LabelContext } from './field';
import type { FieldProps } from './field';
import type { JSX, ParentProps } from 'solid-js';

type CheckboxProps = JSX.InputHTMLAttributes<HTMLInputElement>;

export function CheckboxInternal(inputProps: CheckboxProps) {
	const { data } = useContext(FormContext);
	const [input] = useContext(InputContext);
	const focusable = useFocusable<CheckboxProps>();
	const props = mergeProps(input, focusable, inputProps);

	return (
		<div class="group relative top-1.5 size-6 self-baseline">
			<input
				{...props}
				type="checkbox"
				disabled={false}
				readonly={data.pending || props.readonly || props.disabled}
				aria-required={props.required}
				{...disableAutocomplete(props)}
				class="peer relative size-6 shrink-0 appearance-none rounded-md border border-neutral-500 bg-white outline-none transition-all duration-200 focus-visible:ring-1 focus-visible:ring-brand-700"
			/>
			<svg
				class="pointer-events-none absolute inset-1 block size-4 scale-50 stroke-white transition-all duration-200 peer-checked:scale-100 peer-checked:stroke-brand peer-active:scale-90 peer-active:stroke-brand/80"
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 24 24"
				fill="none"
				stroke-width="4"
				stroke-linecap="round"
				stroke-linejoin="round"
			>
				<polyline points="20 6 9 17 4 12" />
			</svg>
		</div>
	);
}

export function Checkbox(inputProps: CheckboxProps) {
	const ctx = useContext(CheckboxGroupContext);
	const merged = mergeProps({ name: ctx.name }, inputProps);
	const [, props] = splitProps(merged, ['children']);
	return (
		<Field name={props.name}>
			<div class="relative flex items-baseline gap-x-3">
				<CheckboxInternal {...props} />
				{inputProps.children}
			</div>
			{inputProps.name ? <FieldErrors /> : null}
		</Field>
	);
}

const CheckboxGroupContext = createContext<FieldProps>({ name: '' });

export function CheckboxGroup(inputProps: FieldProps) {
	const [other, props] = splitProps(inputProps, ['children']);
	return (
		<CheckboxGroupContext.Provider value={{ name: props.name }}>
			<Field {...props}>
				<Group>{other.children}</Group>
				<FieldErrors />
			</Field>
		</CheckboxGroupContext.Provider>
	);
}

function Group(props: ParentProps) {
	const id = createUniqueId();
	const [, setLabel] = useContext(LabelContext);
	createEffect(() => {
		setLabel({ for: undefined, id });
	});

	return (
		<div role="group" aria-labelledby={id} class="flex flex-col gap-y-2">
			{props.children}
		</div>
	);
}
