import { Match, Show, Switch, createEffect, createSignal, createUniqueId } from 'solid-js';
import { createStore, produce } from 'solid-js/store';
import { Portal } from 'solid-js/web';
import { twMerge } from '@troon/tailwind-preset/merge';
import { Icon } from '@troon/icons';
import { safeTransition } from '../modules/view-transitions';
import { Button } from '../components/button';
import type { JSX, ParentProps } from 'solid-js';

type Toast = {
	children: JSX.Element;
	variant: 'negative' | 'positive' | 'info';
	timeout?: number;
	id: string;
};

export function ToastProvider(props: ParentProps) {
	return (
		<>
			{props.children}
			<ToastContainer />
		</>
	);
}

export type AddToast = (children: JSX.Element, options?: Partial<Omit<Toast, 'children' | 'id'>>) => void;

const [toastQueue, setToastQueue] = createStore<Array<Toast>>([]);

export function addToast(children: JSX.Element, options?: Partial<Omit<Toast, 'children' | 'id'>>) {
	const spreadOptions: Omit<Toast, 'children' | 'id'> = { variant: 'info', ...(options ?? {}) };
	const id = createUniqueId();
	const add = () => {
		setToastQueue(
			produce((queue) => {
				queue.push({ children, ...spreadOptions, id });
			}),
		);
	};
	if (toastQueue.length === 0) {
		safeTransition(add);
	} else {
		add();
	}
}

export function useToast(): AddToast {
	return addToast;
}

export function ToastContainer() {
	const [currentToast, setCurrentToast] = createSignal<Toast>();
	let timeout: NodeJS.Timeout;

	function dismiss() {
		clearTimeout(timeout);
		safeTransition(() => {
			setToastQueue(
				produce((queue) => {
					queue.shift();
				}),
			);
		});
	}

	createEffect(() => {
		if (toastQueue[0] && toastQueue[0] !== currentToast() && toastQueue[0]!.timeout) {
			timeout = setTimeout(() => {
				safeTransition(() => {
					setToastQueue(
						produce((queue) => {
							queue.shift();
						}),
					);
				});
			}, toastQueue[0]!.timeout);
		}
		setCurrentToast(toastQueue[0]);
	});

	return (
		<Portal>
			<Show when={currentToast()}>
				<div class="pointer-events-none fixed inset-4 z-50 flex flex-row items-end justify-center md:inset-8 md:justify-end">
					<div
						role="alert"
						aria-labelledby={currentToast()?.id}
						class={twMerge(
							'pointer-events-auto flex w-auto flex-row items-center gap-2 rounded-md p-4 shadow vt-toast',
							currentToast()?.variant === 'positive' && 'bg-green-100 text-green-500',
							currentToast()?.variant === 'negative' && 'bg-red-100 text-red-500',
							currentToast()?.variant === 'info' && 'bg-blue-100 text-blue-500',
						)}
					>
						<Switch>
							<Match when={currentToast()?.variant === 'positive'}>
								<Icon name="circle-check" class="size-10" title="Success" />
							</Match>
							<Match when={currentToast()?.variant === 'negative'}>
								<Icon name="circle-warning" class="size-10" title="Error" />
							</Match>
							<Match when={currentToast()?.variant === 'info'}>
								<Icon name="info" class="size-10" title="Info" />
							</Match>
						</Switch>
						<p id={currentToast()?.id} class="grow">
							{currentToast()?.children}
						</p>
						<div class="shrink-0 grow-0">
							<Button
								appearance="transparent-current"
								onClick={dismiss}
								action={currentToast()?.variant === 'negative' ? 'danger' : undefined}
							>
								<Icon name="close-md" />
								<span class="sr-only">Dismiss</span>
							</Button>
						</div>
					</div>
				</div>
			</Show>
		</Portal>
	);
}
