import { createContext, useContext } from 'solid-js';
import { ToastProvider } from '../overlays/toast';
import { DialogProvider } from './dialog';
import type { ComponentProps, ParentProps } from 'solid-js';

type Props = ParentProps<{ baseUrl: string } & Partial<ComponentProps<typeof DialogProvider>>>;

export function UIProvider(props: Props) {
	if (!props.baseUrl.startsWith('http')) {
		throw new Error('Invalid host – missing protocol');
	}
	return (
		<HostContext.Provider value={props.baseUrl}>
			<DialogProvider onDialogClosed={props.onDialogClosed} onDialogOpened={props.onDialogOpened}>
				<ToastProvider>{props.children}</ToastProvider>
			</DialogProvider>
		</HostContext.Provider>
	);
}

const HostContext = createContext<string>('https://localhost');

export function useBaseUrl() {
	return useContext(HostContext);
}
