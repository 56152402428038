import { Icon } from '@troon/icons';
import { twJoin } from '@troon/tailwind-preset/merge';
import { Show } from 'solid-js';
import type { JSXElement, ParentProps } from 'solid-js';

type Props = ParentProps<{
	banner?: JSXElement;
	isCurrent?: boolean;
	floating?: boolean;
	variant?: 'plus';
}>;

export function ProductCard(props: Props) {
	return (
		<div
			class={twJoin(
				props.floating && 'shadow-md',
				'relative z-0 flex h-full flex-col overflow-hidden rounded border',
				props.variant === 'plus'
					? 'border-brand-900 bg-gradient-to-r from-neutral-950 to-brand-800 text-white'
					: 'border-neutral bg-white',
			)}
		>
			<Icon
				name="logo-square"
				class="absolute -right-12 -top-16 -z-0 size-64 origin-center -rotate-12 text-brand opacity-20 gradient-mask-bl-0"
			/>
			<Show when={props.banner}>{(banner) => <div class="w-full bg-brand-100 text-brand-600">{banner()}</div>}</Show>
			<div class="z-10 flex size-full flex-col gap-6 px-6 py-8 sm:px-8">
				<Show when={props.isCurrent}>
					<div class="absolute inset-x-0 -top-px h-4 rounded border-t-4 border-brand" />
				</Show>
				{props.children}
			</div>
		</div>
	);
}
