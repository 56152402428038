import { createContext, mergeProps, splitProps, useContext } from 'solid-js';
import type { ParentProps } from 'solid-js';

type CTX = {
	onDialogOpened: (key: string) => void;
	onDialogClosed: (key: string) => void;
};

const noop = () => {};

const Context = createContext<CTX>({ onDialogOpened: noop, onDialogClosed: noop });

export function DialogProvider(props: ParentProps<Partial<CTX>>) {
	const [rest, internal] = splitProps(props, ['children']);
	const value = mergeProps({ onDialogOpened: noop, onDialogClosed: noop }, internal);
	return <Context.Provider value={value}>{rest.children}</Context.Provider>;
}

export function useDialogContext() {
	return useContext(Context);
}
