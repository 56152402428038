import { Link } from '@solidjs/meta';
import { createMemo, Show, splitProps } from 'solid-js';
import type { JSX } from 'solid-js';

type Props = Omit<JSX.ImgHTMLAttributes<HTMLImageElement>, 'sizes'> & {
	crop?: 'face' | 'center' | 'top' | 'bottom' | 'entropy';
	mode?: 'clip' | 'crop' | 'contain' | 'face';
	formats?: Array<string>;
	sizes: Array<[number, number]>;
	preload?: boolean;
};

type Data = {
	images: Array<{ url: string; maxWidth: number; width: number; height: number }>;
	srcset?: string;
	sizes?: string;
};

export function Picture(props: Props) {
	const [internalProps, imgProps] = splitProps(props, ['crop', 'mode', 'sizes', 'preload']);

	const sources = createMemo(() => {
		if (!imgProps.src) {
			return { images: [] } as Data;
		}

		const images = internalProps.sizes
			.sort((a, b) => a[0] - b[0])
			.map(([width, height], index, self) => {
				const url = getImageUrl(imgProps.src!, {
					crop: internalProps.crop,
					mode: internalProps.mode,
					width,
					height,
					format: 'webp',
				});
				return { url, maxWidth: index === self.length - 1 ? Infinity : Math.round(width * 1.25), width, height };
			})
			.flat();

		const data: Data = { images };

		if (images.length > 1) {
			data.srcset = images.map(({ url, width }) => `${url} ${width * 2}w`).join(', ');
			data.sizes = images
				.map(({ maxWidth, width }) => (maxWidth === Infinity ? `${width}px` : `(max-width: ${maxWidth}px) ${width}px`))
				.join(', ');
		}

		return data;
	});

	return (
		<div class={imgProps.class}>
			<Show when={internalProps.preload && sources().images[sources().images.length - 1]?.url}>
				{(url) => (
					<Show
						when={sources().srcset && sources().sizes}
						fallback={<Link rel="preload" fetchpriority="high" as="image" href={url()} crossorigin="anonymous" />}
					>
						<Link
							rel="preload"
							fetchpriority="high"
							as="image"
							href={url()}
							imagesrcset={sources().srcset}
							imagesizes={sources().sizes}
							crossorigin="anonymous"
						/>
					</Show>
				)}
			</Show>
			<img
				width={sources().images[sources().images.length - 1]?.width}
				height={sources().images[sources().images.length - 1]?.height}
				{...imgProps}
				srcset={sources().srcset}
				sizes={sources().sizes}
				src={sources().images[sources().images.length - 1]?.url}
				alt={imgProps.alt ?? ''}
				crossorigin="anonymous"
				fetchpriority={imgProps.loading === 'eager' ? 'high' : 'auto'}
				class={imgProps.class}
			/>
		</div>
	);
}

type ImageParams = {
	crop?: 'face' | 'center' | 'top' | 'bottom' | 'entropy';
	mode?: 'clip' | 'crop' | 'contain' | 'face';
	width: number;
	height: number;
	format?: 'avif' | 'webp';
};

export function getImageUrl(url: string, params: ImageParams) {
	const host = url.includes('contentful') || url.includes('ctfassets') ? 'contentful' : 'imglab';
	return transformers[host](url, params);
}

const transformers: Record<'imglab' | 'contentful', (source: string, params: ImageParams) => string> = {
	imglab: (source, params) => {
		const url = new URL(source);
		url.searchParams.append('width', `${params.width}`);
		url.searchParams.append('height', `${params.height}`);
		url.searchParams.append('format', params.format ?? 'webp');
		url.searchParams.append('mode', params.mode ?? 'crop');
		url.searchParams.append('crop', params.crop ?? 'entropy');
		return url.toString();
	},
	contentful: (source, params) => {
		const url = new URL(source.startsWith('http') ? source : `https:${source}`);
		url.searchParams.append('w', `${params.width}`);
		url.searchParams.append('h', `${params.height}`);
		url.searchParams.append('fm', params.format ?? 'webp');
		url.searchParams.append('fit', imglabModeToContentfulFit[params.mode ?? 'crop']);
		url.searchParams.append('f', imglabCropToContentfulFit[params.crop ?? 'entropy']);
		return url.toString();
	},
};

const imglabCropToContentfulFit: Record<Exclude<ImageParams['crop'], undefined>, string> = {
	face: 'face',
	center: 'center',
	top: 'top',
	bottom: 'bottom',
	entropy: 'center',
};

const imglabModeToContentfulFit: Record<Exclude<ImageParams['mode'], undefined>, string> = {
	clip: 'crop',
	crop: 'fill',
	contain: 'pad',
	face: 'face',
};
