import { twJoin, twMerge } from '@troon/tailwind-preset/merge';
import { splitProps } from 'solid-js';
import type { ParentProps, JSX } from 'solid-js';

type Props = ParentProps<
	JSX.HTMLAttributes<HTMLDivElement> & {
		size?: 'default' | 'small' | 'xsmall';
	}
>;

export function Container(props: Props) {
	return (
		<div
			{...props}
			class={twMerge(
				twJoin(
					'container mx-auto flex w-full flex-col gap-8 px-4 sm:px-6 md:px-12',
					props.size === 'small' && 'max-w-screen-lg',
					props.size === 'xsmall' && 'max-w-screen-sm',
				),
				props.class,
			)}
		/>
	);
}

export function Section(props: Omit<Props, 'size'> & { appearance?: 'primary' | 'secondary' | 'default' }) {
	const [, spread] = splitProps(props, ['appearance']);
	return (
		<div
			{...spread}
			class={twMerge(
				twJoin(
					'flex flex-col gap-8',
					props.appearance && props.appearance !== 'default' && 'py-16 md:py-24',
					props.appearance === 'primary' && 'bg-brand-700 text-white',
					props.appearance === 'secondary' && 'bg-neutral-100',
				),
				props.class,
			)}
		/>
	);
}

export function Page(props: ParentProps<{ class?: string }>) {
	return <div class={twMerge('flex flex-col gap-16 py-8 md:gap-24', props.class)}>{props.children}</div>;
}

export function Prose(props: JSX.HTMLAttributes<HTMLDivElement>) {
	return (
		<Section
			{...props}
			class="prose max-w-none prose-headings:font-semibold prose-h1:text-4xl prose-h2:text-3xl prose-h3:text-2xl md:prose-h1:text-5xl md:prose-h2:text-4xl md:prose-h3:text-3xl"
		/>
	);
}
