// @ts-nocheck
import type { PluginFunc } from 'dayjs';

const plugin: PluginFunc = (_, dayjsClass, d) => {
	// eslint-disable-next-line no-param-reassign, func-names
	dayjsClass.prototype.addInTz = function (...args) {
		const timezone = this.$x.$timezone;
		if (!timezone) {
			throw new Error('No timezone set');
		}
		return d.tz(
			d(this.toDate())
				.tz(timezone)
				.add(...args)
				.format('YYYY-MM-DD HH:mm:ss'),
			timezone,
		);
	};

	// eslint-disable-next-line no-param-reassign, func-names
	dayjsClass.prototype.subtractInTz = function (...args) {
		const timezone = this.$x.$timezone;
		if (!timezone) {
			throw new Error('No timezone set');
		}
		return d.tz(
			d(this.toDate())
				.tz(timezone)
				.subtract(...args)
				.format('YYYY-MM-DD HH:mm:ss'),
			timezone,
		);
	};
};

export default plugin;
