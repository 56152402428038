import { Match, Switch, createUniqueId, splitProps } from 'solid-js';
import type { JSX } from 'solid-js';

type Props = {
	firstName?: string;
	lastName?: string;
} & JSX.SvgSVGAttributes<SVGSVGElement>;

export function Avatar(allProps: Props) {
	const id = createUniqueId();
	const [nonSvgProps, props] = splitProps(allProps, ['firstName', 'lastName']);

	// All values are relative to the viewBox
	// Use `size-X` className to scale the Avatar
	return (
		<div class="leading-0">
			<svg viewBox="0 0 24 24" aria-label={`${nonSvgProps.firstName ?? ''} ${nonSvgProps.lastName ?? ''}`} {...props}>
				<circle cx="12" cy="12" r="12" fill="currentColor" mask={`url(#${id})`} />
				<defs>
					<mask id={id} x="0" y="0" width="24" height="24">
						<rect x="0" y="0" width="24" height="24" fill="white" />
						<Switch>
							<Match when={nonSvgProps.firstName || nonSvgProps.lastName}>
								<text
									x="50%"
									y="50%"
									class="font-semibold leading-none tracking-tight"
									font-size="10px"
									fill="black"
									dominant-baseline="central"
									text-anchor="middle"
								>
									{(nonSvgProps.firstName ?? '')[0]}
									{(nonSvgProps.lastName ?? '')[0]}
								</text>
							</Match>
							<Match when={!nonSvgProps.firstName && !nonSvgProps.lastName}>
								<path
									fill="none"
									stroke="black"
									stroke-linecap="round"
									stroke-linejoin="round"
									stroke-width="2"
									transform="scale(0.6) translate(8,8)"
									d="M20 21c0-2.761-3.582-5-8-5s-8 2.239-8 5m8-8a5 5 0 1 1 0-10a5 5 0 0 1 0 10"
								/>
							</Match>
						</Switch>
					</mask>
				</defs>
			</svg>
		</div>
	);
}
