import { createUniqueId, onCleanup, useContext } from 'solid-js';
import { DescriptionContext } from './field';
import type { ParentProps } from 'solid-js';

export function FieldDescription(props: ParentProps) {
	const { add, remove } = useContext(DescriptionContext);
	const id = createUniqueId();

	add({ id });

	onCleanup(() => {
		remove({ id });
	});

	return (
		<div id={id} class="text-start text-sm text-neutral-800">
			{props.children}
		</div>
	);
}
