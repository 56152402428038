import { twMerge } from '@troon/tailwind-preset/merge';
import type { ParentProps } from 'solid-js';

type Props = ParentProps & {
	class?: string;
};

export function Separator(props: Props) {
	return (
		<div class={twMerge('relative flex items-center justify-center gap-4', props.class)}>
			<div class="grow border-b border-b-current/20" />
			<p>{props.children}</p>
			<div class="grow border-b border-b-current/20" />
		</div>
	);
}
