import { twMerge } from '@troon/tailwind-preset/merge';
import { createUniqueId, splitProps } from 'solid-js';
import type { ParentProps, JSX } from 'solid-js';

export function ActivityIndicator(props: ParentProps & JSX.SvgSVGAttributes<SVGSVGElement>) {
	const id = createUniqueId();
	const [rest, spread] = splitProps(props, ['class', 'viewBox']);

	return (
		<div role="progressbar" class="flex flex-col items-center" aria-labelledby={props.children ? id : undefined}>
			<svg viewBox="0 0 50 50" class={twMerge('size-6 animate-spin', rest.class)} {...spread}>
				<circle
					cx="25"
					cy="25"
					r="20"
					fill="none"
					class="animate-stroke stroke-current"
					stroke-width={8}
					stroke-linecap="round"
				/>
			</svg>
			{props.children ? (
				<div id={id} class="whitespace-nowrap">
					{props.children}
				</div>
			) : null}
		</div>
	);
}
