/* eslint-disable import/export */
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import plugin from './plugin';

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(utc);
dayjs.extend(tz);
dayjs.extend(plugin);

declare module 'dayjs' {
	// @ts-expect-error
	import type { ManipulateType } from 'dayjs';

	interface Dayjs {
		addInTz(value: number, unit?: ManipulateType): Dayjs;
		subtractInTz(value: number, unit?: ManipulateType): Dayjs;
	}
}

export default dayjs;
export type { Dayjs } from 'dayjs';

// @ts-expect-error
export * from 'dayjs/plugin/timezone';
// @ts-expect-error
export * from 'dayjs/plugin/isSameOrAfter';
// @ts-expect-error
export * from 'dayjs/plugin/isSameOrBefore';
