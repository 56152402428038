import { mergeProps, splitProps } from 'solid-js';
import { Dynamic } from 'solid-js/web';
import { twJoin, twMerge } from '@troon/tailwind-preset/merge';
import type { JSX } from 'solid-js';

type HeadingProps = JSX.HTMLAttributes<HTMLHeadingElement> & {
	as: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p';
	size?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p';
};

export function Heading(inputProps: HeadingProps) {
	const [custom, props] = splitProps(mergeProps({ size: inputProps.as }, inputProps), ['as', 'size', 'class']);
	return (
		<Dynamic
			component={custom.as}
			{...props}
			class={twMerge(
				twJoin(
					'font-semibold leading-tight md:leading-snug',
					custom.size === 'h1' && 'text-4xl md:text-5xl',
					custom.size === 'h2' && 'text-3xl md:text-4xl',
					custom.size === 'h3' && 'text-2xl md:text-3xl',
					custom.size === 'h4' && 'text-xl md:text-2xl',
					custom.size === 'h5' && 'text-lg md:text-xl',
					custom.size === 'h6' && 'text-base md:text-lg',
				),
				custom.class,
			)}
		/>
	);
}
