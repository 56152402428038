import { mergeProps, splitProps, useContext } from 'solid-js';
import { twMerge } from '@troon/tailwind-preset/merge';
import { LabelContext } from './field';
import type { JSX } from 'solid-js';

type Props = JSX.LabelHTMLAttributes<HTMLLabelElement> & {
	suppressRequired?: boolean;
	required?: boolean;
};

export function Label(labelProps: Props) {
	const [label] = useContext(LabelContext);
	const merged = mergeProps(label, labelProps);
	const [internal, props] = splitProps(merged, ['suppressRequired', 'required']);

	return (
		<label {...props} class={twMerge('flex gap-1 font-medium', props.class)}>
			{labelProps.children}
			{!internal.suppressRequired && internal.required ? (
				<span aria-hidden class="font-bold text-red-600">
					*
				</span>
			) : null}
		</label>
	);
}
