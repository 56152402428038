import { For, Show, useContext } from 'solid-js';
import { FormContext } from './form';

type Props = {
	fallback?: string;
};

export function Errors(props: Props) {
	const { errors, fieldErrors } = useContext(FormContext);

	return (
		<>
			<Show when={errors().length}>
				<ul aria-live="assertive">
					<For each={errors()}>{(issue) => <li class="text-red-500">{issue}</li>}</For>
				</ul>
			</Show>
			<Show when={!errors().length && Object.keys(fieldErrors()).length}>
				<p class="text-red-500" aria-live="assertive">
					{props.fallback ?? 'There was an issue submitting the form. Please correct the errors and try again.'}
				</p>
			</Show>
		</>
	);
}
