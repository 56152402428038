import { twMerge } from '@troon/tailwind-preset/merge';
import { splitProps } from 'solid-js';
import { Dynamic } from 'solid-js/web';
import type { ComponentProps } from 'solid-js';

export function Callout(
	props: Omit<ComponentProps<typeof Dynamic>, 'component'> & {
		component?: ComponentProps<typeof Dynamic>['component'];
	},
) {
	const [, spread] = splitProps(props, ['component', 'children', 'class']);
	return (
		<Dynamic
			component={props.component ?? 'div'}
			class={twMerge('relative flex flex-col gap-2 rounded bg-neutral-100 p-6', props.class)}
			{...spread}
		>
			<span class="absolute inset-y-0 left-0 w-4 rounded border-s-4 border-s-brand bg-neutral-100 group-aria-current-page:block" />
			{props.children}
		</Dynamic>
	);
}
